<template>
  <div class="FAQ">
    <div class="hearderBox">
            <span @click="backPath">

                <template v-if="isWxWork">返回</template>
                <Svgs v-else name="iconfanhui" color="#000" />
            </span> 
            常用FAQ
        </div>
    <!-- <PublicTitle @back-call="backPath" title="常用FAQ" /> -->
    <div class="FAQContent">
      <div class="question">
        {{ itemInfo.name }}
      </div>
      <div class="answer" v-html="itemInfo.content"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FAQ",
  components: {},
  data() {
    return {
      isWxWork: window.navigator.userAgent.toLowerCase().indexOf('wxwork') !== -1,
      isFirst: true,
      itemInfo: {},
      faqList: [],
      skList: [
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
        {
          width: "100%",
          height: ".2rem",
          mt: ".3rem",
        },
      ],
    };
  },
  activated() {
    this.itemInfo = this.$store.state.FAQItem;
  },
  methods: {
    async getCustomerServiceInfo() {
      let tenant = this.$cookies.get("tenant");
      const params = {
        collection: "cfg-ihelper-faq",
        filter: {
          tenantCode: tenant,
        },
      };

      let _this = this;
      this.services.GetSettings(params).then(function (res) {
        if (res.success) {
          _this.isFirst = false;
          _this.faqList = res.content.faqList;
        }
      });
    },

    backPath() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.FAQ {
  // padding-top: 1rem;
  .FAQContent {
    padding: 0.26rem;

    .question {
      font-size: 0.15rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #14161c;
      line-height: 0.24rem;
    }

    .answer {
      margin-top: 0.24rem;
      font-size: 0.15rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #14161c;
      line-height: 0.2rem;
    }
  }
}
</style>